import { useEffect, useState } from "react"
import axiosInstance from "../../utils/axiosInstance"
import CurrentPath from "../CurrentPath/CurrentPath"
import Messages from "../Chat/Messages/Messages"
import Bottom from "../Chat/Bottom/Bottom"


const Data = () => {
    const [logs, setLogs] = useState([])
    const [chatLogs, setChatLogs] = useState([])
    const [showUsersLog, setShowUsersLog] = useState(true)
    useEffect(() => {
        axiosInstance.get('/get_logs').then(res => {
            console.log(res.data)
            setLogs(res.data.data)
        })
    }, [])

    useEffect(() => {
        if (!showUsersLog) {
            axiosInstance.get('/get_logs/true').then(res => {
                setChatLogs(res.data.data)
            })
        }
    }, [showUsersLog])



    const deleteLog = (id) => {
        axiosInstance.post('/delete_log', { logId: id }).then(res => {
            setLogs(res.data.data)
        })
    }
    const deleteLogChat = (id) => {
        axiosInstance.post('/delete_log/true', { logId: id }).then(res => {
            setChatLogs(res.data.data)
        })
    }

    return (
        <div className="w-100">
            <div className="flex max-w wrap ml-100 flex_direction">
            {logs.map((el,index) => (
                    <div className="flex_direction width_medium bordered mr-10 socket_data">
                        <div className="flex">
                            <span className="f-bold mr-10">Number: </span>
                            {el.connectionNum}
                        </div>
                        <div className="flex infoSocket">
                            <div className="flex">
                                <span className="f-bold mr-10">Id: </span>
                                {el.id}

                            </div>
                            <div className='disconnectBlock'>
                                {el.destroyed &&
                                    <span className="disconnectedButton">Disconnected</span>
                                }
                            </div>
                        </div>
                        <div className="flex infoSocket">
                            <span className="f-bold mr-10">Time: </span>
                            {el.time}
                        </div>
                        <div className="flex infoSocket">
                            <span className="f-bold mr-10">IP: </span>
                            {el.ip}
                        </div>
                        <div className="flex infoSocket">
                            <span className="f-bold mr-10">User Agent: </span>
                            {el.userAgent}
                        </div>
                        <div className="flex infoSocket">
                            <span className="f-bold mr-10">Host: </span>
                            <a href={el.requesterUrl}>{el.requesterUrl}</a>
                        </div>
                        {el.socketInfo.parametrs.data ? Object.keys(el.socketInfo.parametrs.data).map(element => (
                                <div className="flex infoSocket">
                                    <span className="f-bold mr-10">{element}:</span>
                                    {el.socketInfo[element]}
                                </div>
                            ))
                            :
                            Object.keys(el.socketInfo.parametrs).map(element => (
                                <div className="flex infoSocket">
                                    <span className="f-bold mr-10">{element}:</span>
                                    {el.socketInfo[element]}
                                </div>
                            ))
                        }
                        <div className="flex infoSocket">
                            <span className="f-bold mr-10">Ref: </span>
                            {el.socketInfo.ref}
                        </div>
                        </div>
                )).reverse()}
            </div>
        </div>
    )
}

export default Data